import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import AUX from '../../hoc/Aux_';
import {Scrollbars} from 'react-custom-scrollbars';
import $ from 'jquery';
import TransportersAPI from "../../api/transportersAPI";
import LoadsAPI from "../../api/loadsAPI";
import UsersAPI from "../../api/userApi";

const MENU_ENLARGED_WIDTH = 1436

class sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Tab: '',
      SubTab: '',
      MoreTab: '',
      email_menu: false, ui_menu: false, form_menu: false, chart_menu: false,
      table_menu: false, icon_menu: false, map_menu: false, extra_menu: false, pages_menu: false, et_menu: false,
      invites: 0,
      width: $(window).innerWidth(), height: $(window).innerHeight(),
      total_active_loads: 0,
      total_completed_loads: 0,
      total_transporters: 0,
      total_dispatchers: 0,
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  // setActiveTab = (tab, subtab, moretab, e) => {
  //   if (tab === 'transporters') {
  //     throw new Error('Not implemented');
  //   } else {
  //     console.log('setActiveTab', tab, subtab, moretab);
  //   }
  //   this.setState({Tab: tab, SubTab: subtab, MoreTab: moretab});
  // }

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    const {page} = this.props
    this.setState({Tab: page})

    // if ($(window).width() < 1025) {
    //   $('body').addClass('enlarged');
    // } else {
    //   $('body').removeClass('enlarged');

    // }

    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
      if ($("body").hasClass("enlarged")) {
        $("#email_menu_hide").hide();
        $("#ui_menu_hide").hide();
        $("#form_menu_hide").hide();
        $("#chart_menu_hide").hide();
        $("#table_menu_hide").hide();
        $("#icon_menu_hide").hide();
        $("#map_menu_hide").hide();
        $("#page_menu_hide").hide();
        $("#extra_menu_hide").hide();
        $("#et_menu_hide").hide();
      } else {
        $("#email_menu_hide").show();
        $("#ui_menu_hide").show();
        $("#form_menu_hide").show();
        $("#chart_menu_hide").show();
        $("#table_menu_hide").show();
        $("#icon_menu_hide").show();
        $("#map_menu_hide").show();
        $("#page_menu_hide").show();
        $("#extra_menu_hide").show();
        $("#et_menu_hide").show();
      }
    });

    try {
      const responses = await Promise.allSettled([
        TransportersAPI.GetTransporterInvites({}),
        LoadsAPI.GetLoadsData({ page: 1, limit: 1, status: 'active' }),
        LoadsAPI.GetLoadsData({ page: 1, limit: 1, status: 'history' }),
        TransportersAPI.GetTransportersData({ page: 1, limit: 1 }),
        UsersAPI.GetUsersData({ page: 1, limit: 1 }),
      ]);
  
      this.setState({
        invites: responses[0].status === 'fulfilled' ? responses[0].value.data.length : 0,
        total_active_loads: responses[1].status === 'fulfilled' ? responses[1].value.data.data_total_count : 0,
        total_completed_loads: responses[2].status === 'fulfilled' ? responses[2].value.data.data_total_count : 0,
        total_transporters: responses[3].status === 'fulfilled' ? responses[3].value.data.data_total_count : 0,
        total_dispatchers: responses[4].status === 'fulfilled' ? responses[4].value.data.data_total_count : 0,
      });
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // Function to check if a date is not more than 3 months old
  isDateNotMoreThan3MonthsOld(inputDate = new Date()) {
    // Create a new date object for the current date
    const currentDate = new Date();

    // Calculate the date that is 3 months ago from the current date
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    // Compare the input date with the date that is 3 months ago
    return inputDate >= threeMonthsAgo;
  }

  render() {
    const userIsAdmin = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).is_admin === 1 : false;
    // console.log("this.state.width = " + this.state.width);
    if (this.state.width < MENU_ENLARGED_WIDTH) {
      $('body').addClass('enlarged');
    } else {
      $('body').removeClass('enlarged');
    }

    return (
      <AUX>
        <div className="left side-menu">
          <Scrollbars style={{height: 980}}>
            <div>
              <div id="sidebar-menu">
                <ul className="metismenu" id="side-menu">
                  <li>
                    <Link to="/dashboard" className={this.state.Tab === 'dashboard' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-view-dashboard"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Home </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/loads" className={this.state.Tab === 'loads' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-view-list"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Dispatch </span>
                        <span className="label-counter">{this.state.total_active_loads}</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/history" className={this.state.Tab === 'history' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-history"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Accounting </span>
                        <span className="label-counter" style={{ left: '84px' }}>{this.state.total_completed_loads}</span>
                      </span>
                    </Link>
                  </li>
                  <li >
                    <Link to="/transporters" className={this.state.Tab === 'transporters' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-highway"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Drivers </span>
                        <span className="label-counter" style={{ left: '52px' }}>{this.state.total_transporters}</span>
                      </span>
                    </Link>
                  </li>

                  { userIsAdmin ? (
                  <li >
                    <Link to="/dispatchers" className={this.state.Tab === 'dispatchers' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="dripicons dripicons-user-group"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Dispatchers </span>
                        <span className="label-counter" style={{ left: '84px' }}>{this.state.total_dispatchers}</span>
                      </span>
                    </Link>
                  </li>
                  ) : null}

                  <li>
                    <Link to="/clients" className={this.state.Tab === 'clients' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-briefcase-check"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Brokers </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/settings" className={this.state.Tab === 'settings' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-account-settings"/>
                      <span style={{ position: 'relative', display: 'inline-block' }}>
                        <span> Settings </span>
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/help" className={this.state.Tab === 'help' ? 'waves-effect active' : 'waves-effect'}>
                      <i className="mdi mdi-help-circle-outline"/>
                      <span> Help </span>
                    </Link>
                  </li> */}
                </ul>

              </div>
              <div className="clearfix"/>
            </div>
          </Scrollbars>
        </div>
      </AUX>
    );
  }
}

export default withRouter(sidebar);
