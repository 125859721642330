export const showAddTransporter = () => {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    const userShowAddTransporter = localStorage.getItem('userShowAddTransporter') ? JSON.parse(localStorage.getItem('userShowAddTransporter')) : ''

    const today = Date.now()
    const userSinginDate = new Date(userData.date_add)
    const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

    let isTrialMode = false;

    if (today < userLimit && !userShowAddTransporter.popup) {
      localStorage.setItem('userShowAddTransporter', JSON.stringify({popup: true}))
      isTrialMode = true
    }

    return isTrialMode && false;
}

export const showFindTransporter = () => {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    const userShowFindTransporter = localStorage.getItem('userShowFindTransporter') ? JSON.parse(localStorage.getItem('userShowFindTransporter')) : ''

    const today = Date.now()
    const userSinginDate = new Date(userData.date_add)
    const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

    let isTrialMode = false;

    if (today < userLimit && !userShowFindTransporter.popup) {
      localStorage.setItem('userShowFindTransporter', JSON.stringify({popup: true}))
      isTrialMode = true
    }

    return isTrialMode && false;
}

export const showUpgradeSubscription = () => {
    const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
    const userShowUpgradeSubscription = localStorage.getItem('userShowUpgradeSubscription') ? JSON.parse(localStorage.getItem('userShowUpgradeSubscription')) : ''

    const today = Date.now()
    const userSinginDate = new Date(userData.date_add)
    const userLimit = new Date(userSinginDate.setMonth(userSinginDate.getMonth()+1)).getTime();

    let isTrialMode = false;

    if (today < userLimit && !userShowUpgradeSubscription.popup) {
      localStorage.setItem('userShowUpgradeSubscription', JSON.stringify({popup: true}))
      isTrialMode = true
    }

    return isTrialMode && false;
}
