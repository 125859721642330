import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate'
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import {Button, Label} from "reactstrap";
import UsersAPI from "../../../api/userApi";
// import TransporterInvites from "./DispatcherInvites";
import SpeechBubble from '../SpeechBubble/SpeechBubble'
import { showFindTransporter, showUpgradeSubscription } from '../../../helpers/CheckSubscription';
import { AvField, AvForm, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
// import { parseAddress } from "../../../utils/AddressParser";

class Loads extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dispatchers: [],
      selectedItem: [],
      page: 1,
      limit: 10,
      total: 0,
      delayTimer: '',
      commentMode: null,
      commentText: '',
      paginationSearch: '',
      popupOpen: false,
      popupDeleteStatus: '',
      subscriptionStatus: '',
      subscriptionPopupOpen: false,
      awaitForRender: false,
      bubbleDispatcherOpen: false,
      bubbleSubscriptionOpen: false,
      newDispatcherPopupOpen: false,
      comparePass: false,
      accountError: '',
      passwordPattern: false
    };
  }

  async componentDidMount() {
    this.loadDispatchers().then(() => ({}))

    this.setState({
      awaitForRender: true,
      bubbleDispatcherOpen: showFindTransporter(),
      bubbleSubscriptionOpen: false,
    })
  }

  async loadDispatchers() {
    const {limit, page} = this.state
    const data = await UsersAPI.GetUsersData({page, limit});

    this.setState({
      dispatchers: data.data.data, total: data.data.data_total_count,
    })
  }

  selectedItem = (id, i) => {
    const {selectedItem} = this.state;
    const indexId = selectedItem.indexOf(id)

    if (selectedItem.includes(id)) {
      selectedItem.splice(indexId, 1)
    } else {
      selectedItem.push(id)
    }

    this.setState({selectedItem})
  }

  // changeTransportersStatus = async (status) => {
  //   const {selectedItem, transporters, page, limit} = this.state
  //   const filteredTransporters = []
  //
  //   if (selectedItem.length) {
  //
  //     selectedItem.filter((elem) => {
  //       transporters.filter((item) => {
  //         if (item.status !== status && elem === item.id) {
  //           filteredTransporters.push(item.id)
  //         }
  //       })
  //     })
  //
  //     const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: filteredTransporters.join(',')})
  //
  //     if (res.status === 200) {
  //       const data = await TransportersAPI.GetTransportersData({page, limit});
  //       this.setState({dispatchers: data.data.data, total: data.data.data_total_count})
  //
  //     } else {
  //       this.setState({
  //         selectedItem: []
  //       })
  //     }
  //   }
  // }

  handlePageChange = async (page) => {
    const {limit, searchValue} = this.state;
    const data = await UsersAPI.GetUsersData({page: page.selected + 1, limit, search: searchValue});
    this.setState({page: page.selected + 1, dispatchers: data.data.data})
  }

  searching = async (value) => {
    let {delayTimer} = this.state
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: value
    }

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {

      if (value.length >= 3) {
        const data = await UsersAPI.GetUsersData(paginationSearch);
        this.setState({dispatchers: data.data.data, total: data.data.data_total_count});
      } else {
        const data = await UsersAPI.GetUsersData({page: 1, limit: 10});
        this.setState({dispatchers: data.data.data, total: data.data.data_total_count})
      }
    }, 1500);
    this.setState({delayTimer, searchValue: value})

  }

  // handleComment = async (id) => {
  //   const {limit, page, commentText} = this.state;

  //   const res = await TransportersAPI.SaveTransporterComment({driver: id, comment: commentText})
  //   if (res.status === 200) {
  //     const data = await TransportersAPI.GetTransportersData({page, limit});
  //     this.setState({commentMode: null, dispatchers: data.data.data, total: data.data.data_total_count})

  //   } else {
  //     this.setState({commentMode: null,})
  //   }
  // }

  // deleteComment = async (id) => {
  //   const {page, limit} = this.state
  //   const res = await TransportersAPI.DeleteTransporterComment(id)
  //   if (res.status === 200) {
  //     const data = await TransportersAPI.GetTransportersData({page, limit});
  //     this.setState({commentMode: null, dispatchers: data.data.data, total: data.data.data_total_count})
  //   }
  // }

  // sentInvite = async (id) => {
  //   const {searchValue} = this.state;
  //   const paginationSearch = {
  //     page: 1,
  //     limit: 10,
  //     search: searchValue
  //   }
  //   const res = await TransportersAPI.SendInviteToTransporter(id)
  //   if (res.status === 200) {
  //     const data = await TransportersAPI.SearchTransporters(paginationSearch);
  //     this.setState({
  //       dispatchers: data.data.data, 
  //       total: data.data.data_total_count,
  //       bubbleSubscriptionOpen: true,
  //     });
  //   }
  // }

  // unlinkTransporters = async () => {
  //   const {selectedItem, page, limit} = this.state
  //   const res = await TransportersAPI.UnlinkTransporter(selectedItem.join(','));
  //   if (res.status === 200) {
  //     const data = await TransportersAPI.GetTransportersData({page, limit});
  //     this.setState({commentMode: null, dispatchers: data.data.data, total: data.data.data_total_count})
  //   }
  // }

  // switchStatus = async (e, id, status) => {

  //   console.log('[Transporters] - switchStatus', id, status)

  //   let {page, limit, transporters_active, transporters_total} = this.state

  //   let active_transporters = ''
  //   let total_transporters = ''

  //   if (transporters_active < transporters_total) {
  //     if (status !== 'assigned') {
  //       const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: id})

  //       if (res === 200) {
  //         const data = await TransportersAPI.GetTransportersData({page, limit});
  //         this.setState({
  //           dispatchers: data.data.data,
  //           total: data.data.data_total_count,
  //           transporters_active: active_transporters,
  //           transporters_total: total_transporters,
  //         })
  //       }
  //     }

  //   } else if (status === 'active' || status === 'assigned') {
  //     const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: id})

  //     if (res === 200) {
  //       const data = await TransportersAPI.GetTransportersData({page, limit});
  //       this.setState({
  //         dispatchers: data.data.data,
  //         total: data.data.data_total_count,
  //         transporters_active: active_transporters,
  //         transporters_total: total_transporters,
  //       })
  //     }
  //   } else if (status !== 'assigned')  {
  //     this.setState({
  //       subscriptionPopupOpen: true,
  //     })
  //   }
  // }

  openPopup = (id, status) => {
    this.setState({popupOpen: true, popupItemId: id, popupDeleteStatus: status})
  }

  deleteDispatcher = async () => {
    const {popupItemId, page, limit, status} = this.state;
    // if (popupDeleteStatus === 'invited') {
    //   await TransportersAPI.RejectInviteTransporter(popupItemId)
    // } else {
    //   await TransportersAPI.UnlinkTransporter(popupItemId)
    // }
    await UsersAPI.DeactivateUser(popupItemId)
    const data = await UsersAPI.GetUsersData({page, limit, status});
    this.setState({dispatchers: data.data.data, total: data.data.data_total_count, popupOpen: false, popupItemId: ''})
  }

  submitNewDispatcher = async (event, errors, values) => {
    console.log("values = ", values)
    const { email, phoneNumber, firstName, lastName, password, confirmPass, isAdmin } = values;
    const reg = /^(?=.*\w)(?=.*\d)(?=.*[@$!%*#?&])[A-Z,a-z\d@$!%*#?&]{6,}$/

    if (!reg.test(values.password)) {
      return this.setState({passwordPattern: true})
    } else {
      this.setState({passwordPattern: false})
    }

    // const dispatcherId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : ''
    // const dispatcherEmail = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email : ''
    // // const dot = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).dot : ''
    // const dotNum = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).dot_num : ''
    // const enclosedTrailer = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).enclosed_trailer : ''
    // const carCapacity = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).car_capacity : ''
    // const driverCommission = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).driver_commission : ''
    // const termsOfTransportation = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).terms_of_transporation : ''
    // const emailNotifications = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email_notifications : ''
    // const showOnPrintingForm = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).show_on_printing_form : ''
    // const isShowGeo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).is_show_geo : ''
    // const truck = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).truck : ''
    // const sign = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).sign : ''
    // const addressComponents = parseAddress(companyAddress);
    // console.log("addressComponents = ", addressComponents)
      const company = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).company : {}

    const data = {
      email: email,
      password: password,
      user_type: 4,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      is_admin: isAdmin ? 1 : 0,
      company
      // dispatcher_email: dispatcherEmail, //?
      // dot_num: dotNum, //?
      // enclosed_trailer: enclosedTrailer, //?
      // car_capacity: carCapacity, //?
      // address: addressComponents.address, //?
      // city: addressComponents.city, //?
      // state: addressComponents.state, //?
      // zip: addressComponents.zip, //?
      // driver_commission: driverCommission, //?
      // terms_of_transporation: termsOfTransportation, //?
      // email_notifications: emailNotifications, //?
      // show_on_printing_form: showOnPrintingForm, //?
      // is_show_geo: isShowGeo, //?
      // truck: truck, //?
      // sign: sign, //?
      // carrier_name: companyName,
      // dispatcher_id: dispatcherId
    }

    // if (termsConditions) {
      console.log("data = ", data)
      if (password === confirmPass) {
        this.setState({comparePass: false})
        if (errors.length === 0 && password === confirmPass) {

          const res = await UsersAPI.SignUp(data)
          if (res.status === 200) {
            this.setState({comparePass: false, newDispatcherPopupOpen: false})
          } else {
            this.setState({accountError: 'This account already exist'})
          }
        }
      } else {
        this.setState({comparePass: true})
      }
    // }
  }


  openNewDispatcherPopup = () => {
    this.setState({newDispatcherPopupOpen: true})
  }

  render() {
    const { comparePass, accountError, passwordPattern, dispatchers = [], limit, total, popupOpen, newDispatcherPopupOpen, newDispatcherErrorMsg, awaitForRender, bubbleDispatcherOpen, bubbleSubscriptionOpen } = this.state;
    const userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : ''
    const userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).first_name : ''
    const lastName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).last_name : ''

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'dispatchers'}/>
            <CustomTopBar/>
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleDispatcherOpen} 
              speechMode={"findDispatcher"}
              closeCallBack={() => 
                this.setState({
                  bubbleDispatcherOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleSubscriptionOpen && showUpgradeSubscription()} 
              speechMode={"upgradeSubscription"}
              closeCallBack={() => 
                this.setState({
                  bubbleSubscriptionOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Dispatchers</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Dispatchers</li>
                    </ol>
                    <div className="main-content-header-search main-content-header-search-add">
                      <input
                        className="form-control"
                        type="search"
                        id="example-text-input"
                        maxLength="100"
                        onChange={(e) => this.searching(e.target.value)}
                        placeholder="Lookup to for dispatchers, type name, email or phone number..."/>
                        <Button
                              className={"btn btn-primary w-md custom-btn custom-btn" }
                              type="submit"
                              onClick={this.openNewDispatcherPopup}>
                              Create New Dispatcher Profile
                            </Button>
                    </div>
                  </div>
                </div>

                {/* <TransporterInvites onUpdate={() => this.loadDispatchers()}/> */}

                <div className="dashboard-content">

                  <div className="people-table people-table-transporter">
                    <div className="people-table-header">
                      <div className="people-table-header-title">My Dispatchers</div>
                      <div className="people-table-header-search">{total} search results</div>
                    </div>
                    <table>
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Cell</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th style={{ textAlign: 'center',  width: '40px' }}>Email</th>
                        <th style={{ textAlign: 'center',  width: '40px' }}>Status</th>
                        <th>{''}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {dispatchers.map((item, i) => {
                        return (
                          <tr key={item.id}>
                            {/*<td>*/}
                            {/*<div className="custom-control custom-checkbox">*/}
                            {/*<input*/}
                            {/*type="checkbox"*/}
                            {/*className="custom-control-input"*/}
                            {/*id={item.id}*/}
                            {/*onChange={() => this.selectedItem(item.id, i)}*/}
                            {/*checked={!!selectedItem.includes(item.id)}*/}
                            {/*disabled={item.status === 'unknown' || item.status === 'invited' ? 'disabled' : ''}*/}
                            {/*/>*/}
                            {/*<label className="custom-control-label" htmlFor={item.id}/>*/}
                            {/*</div>*/}
                            {/*</td>*/}
                            <td>{item.first_name} {item.last_name}</td>
                            <td><a href={`tel:${item.phone}`} target="_blank" rel="noopener noreferrer">{item.phone}</a></td>
                            <td><a href={`mailto:${item.email}?subject=Mail from TruckMemo Dispatcher&body=Dear ${item.first_name} ${item.last_name}, ... ${userName} ${lastName}`} target="_blank" rel="noopener noreferrer">{item.email}</a></td>
                            <td> 
                              {item.is_admin === 1 && (<span style={{ fontStyle: 'italic', fontWeight: 700 }}>Admin, </span>)}
                              {item.user_type === 4 && (<span style={{ fontStyle: 'italic', fontWeight: 700}}>Dispatcher</span>)}
                              {item.user_type !== 4 && (<span style={{ fontStyle: 'italic', fontWeight: 700 }}>User</span>)}
                            </td>
                            <td>
                              {item.is_veryfied === 1 && (<div className="people-table-status-active">Verified</div>)}
                              {item.is_veryfied !== 1 && (<div className="people-table-status-alert">Not verified</div>)}
                            </td>
                            <td>
                              {item.is_active === 1 && (<div className="people-table-status-active ">Active</div>)}
                              {item.is_active !== 1 && (<div className="people-table-status-disabled">Deactivated</div>)}
                            </td>
                            <td>
                              <div className="people-table-btn-group-control">
                                {item.is_active !== 1 || item.id === userId ?
                                  <div className="people-table-btn-group-icon fas fa-user-minus people-table-btn-delete-lock" title="Deactivate"/> :
                                  <div
                                    className="people-table-btn-group-icon fas fa-user-minus"
                                    title="Deactivate"
                                    onClick={() => this.openPopup(item.id, item.status)}
                                  />
                                }
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                      </tbody>
                    </table>
                    <div className="people-table-footer">
                      {/*<button*/}
                      {/*className="btn custom-btn"*/}
                      {/*onClick={() => this.changeTransportersStatus('active')}>*/}
                      {/*Activate*/}
                      {/*</button>*/}
                      {/*<button*/}
                      {/*className="btn btn-secondary"*/}
                      {/*onClick={() => this.changeTransportersStatus('non_active')}>*/}
                      {/*Deactivate*/}
                      {/*</button>*/}
                      {/*<button*/}
                      {/*className="btn custom-btn-outline"*/}
                      {/*onClick={this.unlinkTransporters}>*/}
                      {/*Unlink*/}
                      {/*</button>*/}

                      <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        marginPagesDisplayed={2}
                        containerClassName={'pagination custom-pagination'}
                        subContainerClassName={'pages pagination '}
                        activeClassName={'active'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        nextClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item disabled'}
                        breakLinkClassName={'page-link disabled'}
                        onPageChange={this.handlePageChange}
                        pageCount={Math.ceil(total / limit)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {popupOpen ? (
            <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">Delete Dispatcher</h5>
                    <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">You really want deactivate this dispatcher?</p>
                    <Button className="btn btn-primary w-md custom-btn" onClick={this.deleteDispatcher}>Delete</Button>
                    <Button className="btn w-md" onClick={() => this.setState({popupOpen: false})}>Cancel</Button>
                  </div>
                </div>
              </div>
            </div>) : null}

            {newDispatcherPopupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Create New Dispatcher Profile</h5>
                      <button type="button" className="close" onClick={() => this.setState({newDispatcherPopupOpen: false, comparePass: false, passwordPattern: false})}>×</button>
                    </div>
                    <div className="modal-body modal-body-clients">

                      <AvForm onSubmit={this.submitNewDispatcher}>
                        <div className="row">
                          <div className="col-12">
                            <AvField
                              name="email"
                              label="Email*"
                              required
                              errorMessage="Invalid email address"
                              type="text"
                              placeholder="Enter dispatcher's email address"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="firstName"
                              label="First Name*"
                              required
                              errorMessage="Invalid first name"
                              type="text"
                              placeholder="Dispatcher's first name"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="lastName"
                              label="Last Name*"
                              required
                              errorMessage="Invalid last name"
                              type="text"
                              placeholder="Dispatcher's last name"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="phoneNumber"
                              label="Phone number*"
                              required
                              type="_tel"
                              maxLength="100"
                              placeholder="Dispatcher's phone number"
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>

                          <div className="col-12"><hr/></div>

                          <div className="form-group">
                          <Label className="col-12" for="isAdmin">Role</Label>
                          
                          <AvCheckboxGroup inline name="isAdmin" style={{ marginLeft: '90px', height: '45px', marginBottom: '0px'}}>
                            <AvCheckbox customInput value="isAdmin">
                              <span style={{ position: 'relative', left: '-160px', maxWidth: '300px' }}>
                                Is <span style={{ fontStyle: 'italic', fontWeight: '700' }}>"Admin"</span> (can create other users and see their's data)
                              </span>
                            </AvCheckbox>
                          </AvCheckboxGroup>
                          </div>

                          <div className="col-12"><hr/></div>

                          <div className="col-12">
                            <AvField
                              name="password"
                              label="Password*"
                              required
                              errorMessage={"Invalid password"}
                              placeholder={'Enter password'}
                              type="password"
                              maxLength="100"
                              validate={{required: {value: true}}}/>
                          </div>

                          <div className="col-12">
                            <AvField
                              name="confirmPass"
                              label="Confirm password*"
                              placeholder={'Enter password'}
                              required
                              type="password"
                              errorMessage="Invalid confirm password"
                              maxLength="100"
                              validate={{required: {value: true}}}/>
                          </div>  

                          <div className="col-12">
                            {comparePass ? (<span className="errorMsg">Passwords do not match</span>) : null}
                            {passwordPattern ?
                              (<span className="errorMsg">Password must contain at least 6 characters, including number and special characters</span>)
                              : null
                            }
                          </div>

                          <div className="col-12"><hr/></div>

                          {/* <div className="col-12">
                            <AvCheckboxGroup inline name="termsConditions" required errorMessage="Driver need to accept the terms and conditions">
                              <AvCheckbox customInput value="termsConditions" className="label-terms-conditions">
                                <span style={{ position: 'relative', left: '-24px' }}>
                                  I agree with <a href="https://truckmemo.com/terms-and-conditions/" className="font-500 text-primary" style={{paddingLeft: '5px'}}>Terms and conditions</a>
                                </span>
                              </AvCheckbox>
                            </AvCheckboxGroup>
                          </div> */}

                        </div>

                        {accountError.length > 0 ? (<span className="errorMsg">{accountError}</span>) : null}
                        {newDispatcherErrorMsg ? <span className="errorMsg">This client already exist or something go wrong</span>:null}
                        
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button className="btn btn-primary w-md custom-btn" type="submit" >Save</Button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>) : null}

        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Loads);
