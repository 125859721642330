import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate'
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import TransportersAPI from "../../../api/transportersAPI";
import {Button} from "reactstrap";
import UsersAPI from "../../../api/userApi";
// import TransporterInvites from "./TransporterInvites";
import SpeechBubble from '../SpeechBubble/SpeechBubble'
import { showFindTransporter, showUpgradeSubscription } from '../../../helpers/CheckSubscription';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { parseAddress } from "../../../utils/AddressParser";

class Loads extends Component {

  constructor(props) {
    super(props);
    this.state = {
      transporters: [],
      selectedItem: [],
      page: 1,
      limit: 10,
      total: 0,
      delayTimer: '',
      commentMode: null,
      commentText: '',
      paginationSearch: '',
      popupOpen: false,
      popupDeleteStatus: '',
      transporters_active: '',
      transporters_total: '',
      subscriptionStatus: '',
      subscriptionPopupOpen: false,
      awaitForRender: false,
      bubbleTransporterOpen: false,
      bubbleSubscriptionOpen: false,
      newDriverPopupOpen: false,
      comparePass: false,
      accountError: '',
      passwordPattern: false
    };
  }

  async componentDidMount() {
    this.loadTransporters().then(() => ({}))

    this.setState({
      awaitForRender: true,
      bubbleTransporterOpen: showFindTransporter(),
      bubbleSubscriptionOpen: false,
    })
  }

  async loadTransporters() {
    const {limit, page} = this.state
    const data = await TransportersAPI.GetTransportersData({page, limit});

    let transporters_active = ''
    let transporters_total = ''

    this.setState({
      transporters: data.data.data, total: data.data.data_total_count,
      transporters_active,
      transporters_total,
    })
  }

  selectedItem = (id, i) => {
    const {selectedItem} = this.state;
    const indexId = selectedItem.indexOf(id)

    if (selectedItem.includes(id)) {
      selectedItem.splice(indexId, 1)
    } else {
      selectedItem.push(id)
    }

    this.setState({selectedItem})
  }

  // changeTransportersStatus = async (status) => {
  //   const {selectedItem, transporters, page, limit} = this.state
  //   const filteredTransporters = []
  //
  //   if (selectedItem.length) {
  //
  //     selectedItem.filter((elem) => {
  //       transporters.filter((item) => {
  //         if (item.status !== status && elem === item.id) {
  //           filteredTransporters.push(item.id)
  //         }
  //       })
  //     })
  //
  //     const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: filteredTransporters.join(',')})
  //
  //     if (res.status === 200) {
  //       const data = await TransportersAPI.GetTransportersData({page, limit});
  //       this.setState({transporters: data.data.data, total: data.data.data_total_count})
  //
  //     } else {
  //       this.setState({
  //         selectedItem: []
  //       })
  //     }
  //   }
  // }

  handlePageChange = async (page) => {
    const {limit} = this.state;
    const data = await TransportersAPI.GetTransportersData({page: page.selected + 1, limit});
    this.setState({page: page.selected + 1, transporters: data.data.data})
  }

  searching = async (value) => {
    let {delayTimer} = this.state
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: value
    }

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {

      if (value.length >= 3) {
        const data = await TransportersAPI.SearchTransporters(paginationSearch);
        this.setState({transporters: data.data.data, total: data.data.data_total_count});
      } else {
        const data = await TransportersAPI.GetTransportersData({page: 1, limit: 10});
        this.setState({transporters: data.data.data, total: data.data.data_total_count})
      }
    }, 1500);
    this.setState({delayTimer, searchValue: value})

  }

  handleComment = async (id) => {
    const {limit, page, commentText} = this.state;

    const res = await TransportersAPI.SaveTransporterComment({driver: id, comment: commentText})
    if (res.status === 200) {
      const data = await TransportersAPI.GetTransportersData({page, limit});
      this.setState({commentMode: null, transporters: data.data.data, total: data.data.data_total_count})

    } else {
      this.setState({commentMode: null,})
    }
  }

  deleteComment = async (id) => {
    const {page, limit} = this.state
    const res = await TransportersAPI.DeleteTransporterComment(id)
    if (res.status === 200) {
      const data = await TransportersAPI.GetTransportersData({page, limit});
      this.setState({commentMode: null, transporters: data.data.data, total: data.data.data_total_count})
    }
  }

  sentInvite = async (id) => {
    const {searchValue} = this.state;
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: searchValue
    }
    const res = await TransportersAPI.SendInviteToTransporter(id)
    if (res.status === 200) {
      const data = await TransportersAPI.SearchTransporters(paginationSearch);
      this.setState({
        transporters: data.data.data, 
        total: data.data.data_total_count,
        bubbleSubscriptionOpen: true,
      });
    }
  }

  // unlinkTransporters = async () => {
  //   const {selectedItem, page, limit} = this.state
  //   const res = await TransportersAPI.UnlinkTransporter(selectedItem.join(','));
  //   if (res.status === 200) {
  //     const data = await TransportersAPI.GetTransportersData({page, limit});
  //     this.setState({commentMode: null, transporters: data.data.data, total: data.data.data_total_count})
  //   }
  // }

  switchStatus = async (e, id, status) => {

    console.log('[Transporters] - switchStatus', id, status)

    let {page, limit} = this.state

    let active_transporters = ''
    let total_transporters = ''

    // if (transporters_active < transporters_total) {
      if (status !== 'assigned') {
        const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: id})

        if (res === 200) {
          const data = await TransportersAPI.GetTransportersData({page, limit});
          this.setState({
            transporters: data.data.data,
            total: data.data.data_total_count,
            transporters_active: active_transporters,
            transporters_total: total_transporters,
          })
        }
      // }

    } else if (status === 'active' || status === 'assigned') {
      const res = await TransportersAPI.ChangeStatusOfTransporters({transporter_id: id})

      if (res === 200) {
        const data = await TransportersAPI.GetTransportersData({page, limit});
        this.setState({
          transporters: data.data.data,
          total: data.data.data_total_count,
          transporters_active: active_transporters,
          transporters_total: total_transporters,
        })
      }
    } else if (status !== 'assigned')  {
      this.setState({
        subscriptionPopupOpen: true,
      })
    }
  }

  openPopup = (id, status) => {
    this.setState({popupOpen: true, popupItemId: id, popupDeleteStatus: status})
  }

  deleteTransporter = async () => {
    const {popupItemId, page, limit, status, popupDeleteStatus} = this.state;
    if (popupDeleteStatus === 'invited') {
      await TransportersAPI.RejectInviteTransporter(popupItemId)
    } else {
      await TransportersAPI.UnlinkTransporter(popupItemId)
    }
    const data = await TransportersAPI.GetTransportersData({page, limit, status});
    this.setState({transporters: data.data.data, total: data.data.data_total_count, popupOpen: false, popupItemId: ''})
  }

  submitNewDriver = async (event, errors, values) => {
    console.log("values = ", values)
    const { email, phoneNumber, firstName, lastName, password, confirmPass } = values;
    const companyName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).company.name : ''
    const companyAddress = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).company.address : ''
    const reg = /^(?=.*\w)(?=.*\d)(?=.*[@$!%*#?&])[A-Z,a-z\d@$!%*#?&]{6,}$/

    if (!reg.test(values.password)) {
      return this.setState({passwordPattern: true})
    } else {
      this.setState({passwordPattern: false})
    }

    const dispatcherId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : ''
    const dispatcherEmail = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email : ''
    // const dot = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).dot : ''
    const dotNum = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).dot_num : ''
    const enclosedTrailer = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).enclosed_trailer : ''
    const carCapacity = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).car_capacity : ''
    const driverCommission = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).driver_commission : ''
    const termsOfTransportation = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).terms_of_transporation : ''
    const emailNotifications = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email_notifications : ''
    const showOnPrintingForm = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).show_on_printing_form : ''
    const isShowGeo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).is_show_geo : ''
    const truck = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).truck : ''
    const sign = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).sign : ''
    const addressComponents = parseAddress(companyAddress);
    console.log("addressComponents = ", addressComponents)

    const data = {
      email: email,
      password: password,
      user_type: 1,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      dispatcher_email: dispatcherEmail, //?
      dot: companyName, //?
      dot_num: dotNum, //?
      enclosed_trailer: enclosedTrailer, //?
      car_capacity: carCapacity, //?
      address: addressComponents.address, //?
      city: addressComponents.city, //?
      state: addressComponents.state, //?
      zip: addressComponents.zip, //?
      driver_commission: driverCommission, //?
      terms_of_transporation: termsOfTransportation, //?
      email_notifications: emailNotifications, //?
      show_on_printing_form: showOnPrintingForm, //?
      is_show_geo: isShowGeo, //?
      truck: truck, //?
      sign: sign, //?
      carrier_name: companyName,
      dispatcher_id: dispatcherId
    }

    // if (termsConditions) {
      console.log("data = ", data)
      if (password === confirmPass) {
        this.setState({comparePass: false})
        if (errors.length === 0 && password === confirmPass) {

          const res = await UsersAPI.SignUp(data)
          if (res.status === 200) {
            this.setState({comparePass: false, newDriverPopupOpen: false})
          } else {
            this.setState({accountError: 'This account already exist'})
          }
        }
      } else {
        this.setState({comparePass: true})
      }
    // }
  }


  openNewDriverPopup = () => {
    this.setState({newDriverPopupOpen: true})
  }

  render() {
    const { comparePass, accountError, passwordPattern, transporters = [], limit, total, commentMode, commentText, popupOpen, newDriverPopupOpen, newDriverErrorMsg, awaitForRender, bubbleTransporterOpen, bubbleSubscriptionOpen } = this.state;
    const userName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).first_name : ''
    const lastName = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).last_name : ''

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'transporters'}/>
            <CustomTopBar/>
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleTransporterOpen} 
              speechMode={"findTransporter"}
              closeCallBack={() => 
                this.setState({
                  bubbleTransporterOpen: false,
                })
              }
            />
            <SpeechBubble 
              enabled={awaitForRender && !popupOpen && bubbleSubscriptionOpen && showUpgradeSubscription()} 
              speechMode={"upgradeSubscription"}
              closeCallBack={() => 
                this.setState({
                  bubbleSubscriptionOpen: false,
                })
              }
            />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Drivers</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Drivers</li>
                    </ol>
                    <div className="main-content-header-search main-content-header-search-add">
                      <input
                        className="form-control"
                        type="search"
                        id="example-text-input"
                        maxLength="100"
                        onChange={(e) => this.searching(e.target.value)}
                        placeholder="Lookup to connect to drivers who are already onboard, type name, email or phone number..."/>
                        <Button
                              className={"btn btn-primary w-md custom-btn custom-btn" }
                              type="submit"
                              onClick={this.openNewDriverPopup}>
                              Create New Driver Profile
                            </Button>
                    </div>
                  </div>
                </div>

                {/* <TransporterInvites onUpdate={() => this.loadTransporters()}/> */}

                <div className="dashboard-content">

                  <div className="people-table people-table-transporter">
                    <div className="people-table-header">
                      <div className="people-table-header-title">My Drivers</div>
                      <div className="people-table-header-search">{total} search results</div>
                    </div>
                    <table>
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Cell</th>
                        <th>Carrier</th>
                        <th>Email</th>
                        <th>Comment (private)</th>
                        <th>Available capacity</th>
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th>{''}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {transporters.map((item, i) => {
                        return (
                          <tr key={item.id}>
                            {/*<td>*/}
                            {/*<div className="custom-control custom-checkbox">*/}
                            {/*<input*/}
                            {/*type="checkbox"*/}
                            {/*className="custom-control-input"*/}
                            {/*id={item.id}*/}
                            {/*onChange={() => this.selectedItem(item.id, i)}*/}
                            {/*checked={!!selectedItem.includes(item.id)}*/}
                            {/*disabled={item.status === 'unknown' || item.status === 'invited' ? 'disabled' : ''}*/}
                            {/*/>*/}
                            {/*<label className="custom-control-label" htmlFor={item.id}/>*/}
                            {/*</div>*/}
                            {/*</td>*/}
                            <td>{item.first_name} {item.last_name}</td>
                            <td><a href={`tel:${item.phone}`} target="_blank" rel="noopener noreferrer">{item.phone}</a></td>
                            <td>{item.carrier_name ? item.carrier_name : item.dot}</td>
                            <td><a href={`mailto:${item.email}?subject=Mail from TruckMemo Driver&body=Dear ${item.first_name} ${item.last_name}, ... ${userName} ${lastName}`} target="_blank" rel="noopener noreferrer">{item.email}</a></td>
                            <td>
                              <div className="people-table-comment">
                                {commentMode === item.id ?
                                  (<div className="people-table-comment-form">
                                    <textarea
                                      onChange={(e) => this.setState({commentText: e.target.value})}
                                      value={commentMode ? commentText : item.transporter_comment}/>
                                    <p onClick={() => this.handleComment(item.id)}>Save</p>
                                    <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                                  </div>)
                                  : (
                                    <div className='people-table-comment-control'>
                                      <div className="people-table-comment-text">{item.transporter_comment}</div>
                                      {item.transporter_comment ? (
                                          <div className='people-table-comment-form-btns'>
                                            <p onClick={() => this.setState({commentMode: item.id, commentText: item.transporter_comment})}>
                                              {'Edit'}
                                            </p>
                                            <span onClick={() => this.deleteComment(item.id)}>Delete</span>
                                          </div>
                                        ) :
                                        <p onClick={() => this.setState({commentMode: item.id, commentText: item.transporter_comment})}>
                                          {'Add'}
                                        </p>
                                      }
                                    </div>
                                  )}
                              </div>
                            </td>
                            <td>
                              <div className="people-table-capacity">
                                <span>{item.car_capacity - item.assigned_car_count}</span> / <span>{item.car_capacity}</span>
                              </div>
                            </td>
                            <td>
                              {item.status === 'active' && (<div className="people-table-status-active ">Active</div>)}
                              {item.status === 'assigned' && (<div className="people-table-status-assigned ">Assigned</div>)}
                              {item.status === "non_active" && (<div className="people-table-status-disabled">Not active</div>)}
                              {item.status === 'invited' && (<div className="people-table-status-alert">Invited</div>)}
                              {item.status === 'unknown' &&
                              (<div
                                  className="btn btn-sm custom-btn btn-outline-primary"
                                  style={{whiteSpace: 'nowrap'}}
                                  onClick={() => this.sentInvite(item.id)}
                                >
                                  Send invite
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="people-table-btn-group-control">
                                <div className="people-table-btn-group-toggle">

                                  {item.status === 'active' || item.status === 'non_active' || item.status === 'assigned' ?

                                    (<label
                                      htmlFor={item.id}
                                      title={"Switcher of status"}>
                                      <input
                                        type="checkbox"
                                        id={item.id}
                                        checked={item.status === 'active' || item.status === 'assigned'}
                                        onChange={(e) => this.switchStatus(e, item.id, item.status)}/>
                                      <span className="people-table-btn-group-toggle-icon"/>
                                      <span className="people-table-btn-group-toggle-active">Active</span>
                                      <span className="people-table-btn-group-toggle-notActive">Not active</span>
                                    </label>) : ''
                                  }
                                </div>

                                {item.status === 'active' || item.status === 'assigned' || item.status === 'unknown' ?
                                  <div className="people-table-btn-group-icon fas fa-trash people-table-btn-delete-lock" title="Delete"/> :
                                  <div
                                    className="people-table-btn-group-icon fas fa-trash"
                                    title="Delete"
                                    onClick={() => this.openPopup(item.id, item.status)}
                                  />
                                }
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                      </tbody>
                    </table>
                    <div className="people-table-footer">
                      {/*<button*/}
                      {/*className="btn custom-btn"*/}
                      {/*onClick={() => this.changeTransportersStatus('active')}>*/}
                      {/*Activate*/}
                      {/*</button>*/}
                      {/*<button*/}
                      {/*className="btn btn-secondary"*/}
                      {/*onClick={() => this.changeTransportersStatus('non_active')}>*/}
                      {/*Deactivate*/}
                      {/*</button>*/}
                      {/*<button*/}
                      {/*className="btn custom-btn-outline"*/}
                      {/*onClick={this.unlinkTransporters}>*/}
                      {/*Unlink*/}
                      {/*</button>*/}

                      <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        marginPagesDisplayed={2}
                        containerClassName={'pagination custom-pagination'}
                        subContainerClassName={'pages pagination '}
                        activeClassName={'active'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        nextClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item disabled'}
                        breakLinkClassName={'page-link disabled'}
                        onPageChange={this.handlePageChange}
                        pageCount={Math.ceil(total / limit)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {popupOpen ? (
            <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">Delete Transporter</h5>
                    <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">You really want delete this transporter?</p>
                    <Button className="btn btn-primary w-md custom-btn" onClick={this.deleteTransporter}>Delete</Button>
                    <Button className="btn w-md" onClick={() => this.setState({popupOpen: false})}>Cancel</Button>
                  </div>
                </div>
              </div>
            </div>) : null}

            {newDriverPopupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Create New Driver Profile</h5>
                      <button type="button" className="close" onClick={() => this.setState({newDriverPopupOpen: false, comparePass: false, passwordPattern: false})}>×</button>
                    </div>
                    <div className="modal-body modal-body-clients">

                      <AvForm onSubmit={this.submitNewDriver}>
                        <div className="row">
                          <div className="col-12">
                            <AvField
                              name="email"
                              label="Email*"
                              required
                              errorMessage="Invalid email address"
                              type="text"
                              placeholder="Enter your driver's email address"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="firstName"
                              label="First Name*"
                              required
                              errorMessage="Invalid first name"
                              type="text"
                              placeholder="Driver's first name"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="lastName"
                              label="Last Name*"
                              required
                              errorMessage="Invalid last name"
                              type="text"
                              placeholder="Driver's last name"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="phoneNumber"
                              label="Phone number*"
                              required
                              type="_tel"
                              maxLength="100"
                              placeholder="Driver's phone number"
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>

                          <div className="col-12"><hr/></div>

                          {/* <div className="col-12">
                            <AvField
                              name="companyName"
                              label="Company name*"
                              required
                              errorMessage="Invalid company name"
                              value={ defaultCompany }
                              type="text"
                              maxLength="100"
                              placeholder="Carrier company name"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>
                          <div className="col-12">
                            <AvField
                              name="companyAddress"
                              label="Carrier address*"
                              required
                              errorMessage="Invalid address name"
                              value={ defaultAddress }
                              type="text"
                              maxLength="100"
                              placeholder="Carrier's address"
                              validate={{required: {value: true}}}
                              // onChange={(e) => this.handleChange(e)}
                              />
                          </div>

                          <div className="col-12"><hr/></div> */}

                          <div className="col-12">
                            <AvField
                              name="password"
                              label="Password*"
                              required
                              errorMessage={"Invalid password"}
                              placeholder={'Enter password'}
                              type="password"
                              maxLength="100"
                              validate={{required: {value: true}}}/>
                          </div>

                          <div className="col-12">
                            <AvField
                              name="confirmPass"
                              label="Confirm password*"
                              placeholder={'Enter password'}
                              required
                              type="password"
                              errorMessage="Invalid confirm password"
                              maxLength="100"
                              validate={{required: {value: true}}}/>
                          </div>  

                          <div className="col-12">
                            {comparePass ? (<span className="errorMsg">Passwords do not match</span>) : null}
                            {passwordPattern ?
                              (<span className="errorMsg">Password must contain at least 6 characters, including number and special characters</span>)
                              : null
                            }
                          </div>

                          <div className="col-12"><hr/></div>

                          {/* <div className="col-12">
                            <AvCheckboxGroup inline name="termsConditions" required errorMessage="Driver need to accept the terms and conditions">
                              <AvCheckbox customInput value="termsConditions" className="label-terms-conditions">
                                <span style={{ position: 'relative', left: '-24px' }}>
                                  I agree with <a href="https://truckmemo.com/terms-and-conditions/" className="font-500 text-primary" style={{paddingLeft: '5px'}}>Terms and conditions</a>
                                </span>
                              </AvCheckbox>
                            </AvCheckboxGroup>
                          </div> */}

                        </div>

                        {accountError.length > 0 ? (<span className="errorMsg">{accountError}</span>) : null}
                        {newDriverErrorMsg ? <span className="errorMsg">This client already exist or something go wrong</span>:null}
                        
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button className="btn btn-primary w-md custom-btn" type="submit">Save</Button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>) : null}

        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Loads);
