import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  Hint,
} from 'react-vis';

import './style.css'
import 'react-vis/dist/style.css';

import {Tooltip} from "reactstrap";
import {bindActionCreators} from "redux";
import {getDashboardData} from "../MainPage/dashboardAction";
import DashboardAPI from '../../../api/dashboardApi'
import GetDrivers from "../../../helpers/GetDrivers";
import Select from "react-select";


class CompletedLoadsStat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip_top: false,
      useCanvas: false,
      weekOld: [],
      weekNew: [],
      yearOld: [],
      yearNew: [],
      defaultWidth: 550,
      defaultHeight: 270,
      transporters: [],
      defaultSelectValue: {label: 'All drivers', value: ''}
    }
  }

  setDataDidMount = async (driver_id = null, dashboardGetData = null) => {
    const
      { dashboardData } = this.props,
      windowWidth = window.screen.width,
      dateValue = 'year',
      data = await DashboardAPI.CompletedLoadStat({
        period: dateValue,
        ...(driver_id ? {driver_id} : {})
      }),
      weekOld = [],
      weekNew = [],
      yearOld = [],
      yearNew = []
    let
      maxWeekValuesArr = [],
      maxYearValuesArr = [],
      maxWeekValue = 0,
      maxYearValue = 0

    if ((dashboardData && dashboardData.loadCompletedMonth) || (dashboardGetData && dashboardGetData.loadCompletedMonth)) {
      const completedLoad = Object.values(dashboardGetData ? dashboardGetData.loadCompletedMonth : dashboardData.loadCompletedMonth)
      completedLoad.forEach((item) => {
        item[0].size = 10
        item[0].style= {fontSize: '10px'}
        item[0].xOffset= 33
        item[0].yOffset = 0
        item[0].rotation = 0

        item[1].size = 10
        item[1].style= {fontSize: '10px'}
        item[1].xOffset= -15
        item[1].yOffset = 0
        item[1].rotation = 0

        weekOld.push(item[0]);
        weekNew.push(item[1]);
        maxWeekValuesArr.push(item[0].y)
        maxWeekValuesArr.push(item[1].y)
      })
      maxWeekValue = Math.max(...maxWeekValuesArr)
    }

    if (data.data) {
      const completedLoadYear = Object.values(data.data.data);

      completedLoadYear.forEach( item => {
        item[0].size = 10
        item[0].style= {fontSize: '10px'}
        item[0].xOffset= -10
        item[0].yOffset = 0
        item[0].rotation = 0

        item[1].size = 10
        item[1].style= {fontSize: '10px'}
        item[1].xOffset= 24
        item[1].yOffset = 0
        item[1].rotation = 0

        yearOld.push(item[0]);
        yearNew.push(item[1]);
        maxYearValuesArr.push(item[0].y);
        maxYearValuesArr.push(item[1].y);
      });

      maxYearValue = Math.max(...maxYearValuesArr);
    }



    // REMOVE DEMO DATE
    //==================================

    // weekOld[0] = {x: "Sunday", y: 3, label:'text1', size: 10, style: {fontSize: 10}, xOffset: 38, yOffset: 0, rotation: 0}
    // weekNew[0] = {x: "Sunday", y: 5, label:'text2', size: 10, style: {fontSize: 10}, xOffset: -15, yOffset: 0, rotation: 0}
    //
    // weekOld[1] = {x: "Monday", y: 3, label:'text3', size: 10, style: {fontSize: 10}, xOffset: 38, yOffset: 0, rotation: 0}
    // weekNew[1] = {x: "Monday", y: 5, label:'text4', size: 10, style: {fontSize: 10}, xOffset: -15, yOffset: 0, rotation: 0}

    // weekOld[2] = {x: "Wednesday", y: 3, label:'text5', size: 10, style: {fontSize: 10}, xOffset: 38, yOffset: 0, rotation: 0}
    // weekNew[2] = {x: "Wednesday", y: 5, label:'text6', size: 10, style: {fontSize: 10}, xOffset: -15, yOffset: 0, rotation: 0}

    //
    // yearOld[0] = {x: "January", y: 3, label:'text3',xOffset: -10, yOffset: 0}
    // yearNew[0] = {x: "January", y: 2, label:'text4',xOffset: 26, yOffset: 0}
    //==================================

    const transporters = await GetDrivers()

    this.setState({
      transporters,
      weekOld,
      weekNew,
      yearOld,
      yearNew,
      defaultWidth: windowWidth > 1600 ? 800 : 600,
      defaultHeight: windowWidth > 1600 ? 400 : 300,

      maxWeekValue,
      maxYearValue
    })
  }

  async componentDidMount() {
    await this.setDataDidMount()
  }

  tooltipDynamicPosition = (value, e, max) => {
    //console.log('>>> LOG', e); /*this.setState({hintValue: value})*/
    if (this.state.hintValue)
      this.setState({hintY: e.event.layerY * ( 1 / (max) * this.state.hintValue.y )})

  };

  tooltipOff = (datapoint, e) => {
    this.setState({hintValue: false})
  };

  tooltipOn = (datapoint, e) => {
    this.setState({hintValue: datapoint})
  };

  handleDriver = async (value) => {
    // const data = await DashboardAPI.GetDashboardData({
    //   ...(value.id ? {driver_id: value.id} : {})
    // })
    // await this.setDataDidMount(value.id || null, data.data.data || null)

    await DashboardAPI.GetDashboardData({
      ...(value.id ? {driver_id: value.id} : {})
    }).then(res => {
      if (res && res.data) {
        this.setDataDidMount(value.id || null, res.data.data || null)
      }
    }).catch(err => {
      console.error("[MainPage] handleDriver", err);
    })
  }

  render() {

    const {
      useCanvas,
      weekOld,
      weekNew,
      yearOld,
      yearNew,
      defaultWidth,
      defaultHeight,

      maxWeekValue,
      maxYearValue,
      defaultSelectValue,
      transporters
    } = this.state

    const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries

    // console.log('>>> maxWeekValue: ', maxWeekValue)
    return (
      <AUX>
        <div className="card">
          <div className="card-body">
            <h5 className="mt-0 custom-card-header card-body-title">Completed Loads
              {this.props.refreshing ? 
                <span style={{ marginLeft: '3px', marginRight: '3px' }}><i className="fa fa-spinner fa-spin"/></span>
               : null}
              <span className="custom-info" id="TooltipTop2">i</span>
              <div className="people-table-header-filter _mydrivers">
                <ul className="load-table-header header-second">
                  <li>
                    <span className="custom-form-control-wrap">
                      <Select
                        className="custom-form-control-select-drivers _filters"
                        defaultValue={defaultSelectValue}
                        onChange={this.handleDriver}
                        options={transporters}
                      />
                    </span>
                  </li>
                </ul>
              </div>
              <Tooltip placement="top" isOpen={this.state.tooltip_top} target="TooltipTop2"
                       toggle={() => this.setState({tooltip_top: !this.state.tooltip_top})}>
                This widget shows the number of completed loads by your transporters per period of time and
                compares it with a similar period in the past. You can view a weekly or yearly reports.
                The yearly report shows your gross earnings by month.
              </Tooltip>
            </h5>


            <ul className="nav nav-tabs nav-tabs-custom nav-tabs-custom-loads" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#home1" role="tab">
                  <span className="d-block d-sm-none"><i className="far fa-user"/></span>
                  <span className="d-none d-sm-block">Weeks</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#profile1" role="tab">
                  <span className="d-block d-sm-none"><i className="far fa-user"/></span>
                  <span className="d-none d-sm-block">Months</span>
                </a>
              </li>
            </ul>

            <div className="tab-content completedLoads" id="completedLoads">
              <div className="tab-pane active" id="home1" role="tabpanel">
                <XYPlot xType="ordinal" width={defaultWidth} height={defaultHeight} margin={{left: 60}} yDomain={[0, maxWeekValue || 10 /*> 8 ? maxWeekValue : 10 */ ]}
                        onMouseLeave={ this.tooltipOff }
                >
                  <VerticalGridLines/>
                  <HorizontalGridLines/>
                  <XAxis/>
                  <YAxis tickFormat={tick => '$ ' + tick} />
                  <BarSeries className="vertical-bar-series-example" data={weekOld}
                             onValueMouseOver={ this.tooltipOn } onValueMouseOut={ this.tooltipOff } onNearestXY={ (val, e) => { this.tooltipDynamicPosition(val, e, maxWeekValue) }}
                  />
                  <BarSeries className="vertical-bar-series-example" data={weekNew}
                             onValueMouseOver={ this.tooltipOn } onValueMouseOut={ this.tooltipOff } onNearestXY={ (val, e) => { this.tooltipDynamicPosition(val, e, maxWeekValue) } }
                  />
                  {this.state.hintValue ? <Hint
                    value={ this.state.hintValue } align={{horizontal: 'right', vertical: 'bottom'}} style={{marginTop: this.state.hintY - 60 + 'px', marginLeft: -40 + 'px'}}>
                    <div className="toolTipBar">
                      <div className="wrap">{this.state.hintValue.label}</div>
                    </div>
                  </Hint> : null}
                </XYPlot>
                <div className="completedLoadsView">
                  <div className="completedLoadsViewItem completedLoadsViewItem--old">Last period</div>
                  <div className="completedLoadsViewItem completedLoadsViewItem--new">This period</div>
                </div>
              </div>
              <div className="tab-pane" id="profile1" role="tabpanel">
                <XYPlot xType="ordinal" width={defaultWidth} height={defaultHeight}  margin={{left: 60}} yDomain={[0, maxYearValue || 10 /* > 8 ? maxYearValue : 10 */]}
                        onMouseLeave={ this.tooltipOff }
                >
                  <VerticalGridLines/>
                  <HorizontalGridLines/>
                  <XAxis/>
                  <YAxis tickFormat={tick => '$ ' + tick}/>
                  <BarSeries  className="vertical-bar-series-example" data={yearOld} //onNearestXY={ value => { console.log('>>> LOG', value); this.setState({hintValue: value})} }
                              onValueMouseOver={ this.tooltipOn } onValueMouseOut={ this.tooltipOff } onNearestXY={ (val, e) => { this.tooltipDynamicPosition(val, e, maxYearValue) } }
                  />
                  <BarSeries className="vertical-bar-series-example" data={yearNew}
                             onValueMouseOver={ this.tooltipOn } onValueMouseOut={ this.tooltipOff } onNearestXY={ (val, e) => { this.tooltipDynamicPosition(val, e, maxYearValue) } }
                  />
                  {this.state.hintValue ? <Hint
                    value={ this.state.hintValue } align={{horizontal: 'right', vertical: 'bottom'}} style={{marginTop: this.state.hintY - 60 + 'px', marginLeft: -40 + 'px'}}>
                    <div className="toolTipBar">
                      <div className="wrap">{this.state.hintValue.label}</div>
                    </div>
                  </Hint> : null}
                </XYPlot>



                <div className="completedLoadsView">
                  <div className="completedLoadsViewItem completedLoadsViewItem--old">Last period</div>
                  <div className="completedLoadsViewItem completedLoadsViewItem--new">This period</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    ...state.dashboardReducer,
  };
}


const mapDispatchToProps = dipatch => ({
  ...bindActionCreators(
    {
      getDashboardData,
    },
    dipatch
  ),
});

export default connect(mapStatetoProps, mapDispatchToProps)(CompletedLoadsStat);

