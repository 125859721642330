export const months = {
    '01': 'JAN',
    '02': 'FEB',
    '03': 'MAR',
    '04': 'APR',
    '05': 'MAY',
    '06': 'JUN',
    '07': 'JUL',
    '08': 'AUG',
    '09': 'SEP',
    '10': 'OCT',
    '11': 'NOV',
    '12': 'DEC',
  }
  
  export function daysSelected(startDate, endDate) {
    return Math.round((((endDate ? new Date(endDate) : new Date(new Date().toDateString())) - (startDate ? new Date(startDate) : new Date(new Date().toDateString()))) / (1000 * 60 * 60 * 24) + 1));
  }

  export function getFormattedDate(fullDate) {
    let dateTimeSplitted, dateSplitted;

    if (fullDate) {
      dateTimeSplitted = (fullDate + '').split(' ')
      dateSplitted = dateTimeSplitted[0].split('-')

      const [month, day] = [(dateSplitted[1] || '-'), (dateSplitted[2] || '-')];
      return dateSplitted[0] !== 'null' || dateSplitted[0] ? [months[month], day].join('-') : '-';
    } else {
      return '-';
    }
  }

  export function formatShortDate(inputDateString) {
    if (!inputDateString) {
      return null;
    }
  
    const inputDate = new Date(inputDateString);
    
    if (isNaN(inputDate.getTime())) {
      // Invalid date string
      return "Invalid Date";
    }
    
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();
    
    return `${month}/${day}/${year}`;
  }

  export function formatInputDate(inputDateString = new Date()) {
    if (!inputDateString) {
      return null;
    }
  
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate.getTime())) {
      // Invalid date string
      return "Invalid Date";
    }

    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();
    
    return `${year}-${month}-${day}`;
  }

  export function createLocalTimeDate(dateString) {
    // Create a new Date object from the input date string
    const inputDate = dateString ? new Date(dateString.split(' ')[0] + "T00:00:00" + getLocalTimezoneOffset()) : new Date();
  
    // Create a new Date object using the input date but with the current local time
    const localTimeDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), 0, 0, 0, 0);
    
    return localTimeDate;
  }

  export function getLocalTimezoneOffset() {
    const now = new Date();
    const offset = now.getTimezoneOffset(); // in minutes
    const absoluteOffset = Math.abs(offset);
    
    const hours = Math.floor(absoluteOffset / 60);
    const minutes = absoluteOffset % 60;
    
    const sign = offset <= 0 ? "+" : "-"; // if offset is negative or zero, it’s ahead of UTC
    
    return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  export function timeAgo(dateString) {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now - past) / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(diffInSeconds / 3600);
    const days = Math.floor(diffInSeconds / 86400);
    const months = Math.floor(diffInSeconds / (86400 * 30));
    const years = Math.floor(diffInSeconds / (86400 * 365));

    const pluralize = (value, unit) => value === 1 ? `${value} ${unit} ago` : `${value} ${unit}s ago`;

    if (diffInSeconds < 60) {
        return pluralize(diffInSeconds, 'second');
    } else if (minutes < 60) {
        return pluralize(minutes, 'minute');
    } else if (hours < 24) {
        return pluralize(hours, 'hour');
    } else if (days < 30) {
        return pluralize(days, 'day');
    } else if (months < 12) {
        return pluralize(months, 'month');
    } else {
        return pluralize(years, 'year');
    }
  }

  export const formatDateToUTC = (date) => {
    try {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    } catch (error) {
      console.error('Error in formatDateToUTC', error);
      return '';
    }
  };

  export function convertUTCToLocal(utcDateTimeString) {
    try {
      // Create a Date object from the UTC string
      const utcDate = new Date(utcDateTimeString + "Z"); // "Z" implies UTC
  
      // Extract local date components
      const year = utcDate.getFullYear();
      const month = String(utcDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(utcDate.getDate()).padStart(2, '0');
      const hours = String(utcDate.getHours()).padStart(2, '0');
      const minutes = String(utcDate.getMinutes()).padStart(2, '0');
      const seconds = String(utcDate.getSeconds()).padStart(2, '0');
  
      // Format as "YYYY-MM-DD HH:MM:SS"
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.error('Error in convertUTCToLocal', error);
      return '';
    }
  }

  export function convertUTCToLocalDate(utcDateTimeString) {
    try {
      // Create a Date object from the UTC string
      const utcDate = new Date(utcDateTimeString + "Z"); // "Z" implies UTC
  
      return utcDate; // Return the Date instance, which will be converted to local time
    } catch (error) {
      console.error('Error in convertUTCToLocalDate', error);
      return null; // Return null in case of an error
    }
  }