import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import {Link} from 'react-router-dom';
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";

class Help extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subscriptionStatus: '',
    };
  }

  async componentDidMount() {
  }


  render() {

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'help'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Help</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Help</li>
                    </ol>
                  </div>

                  <div className="main-content-header-right">
                    <a href='https://truckmemo.com/support/' className="btn custom-btn main-content-header-btn" style={{marginTop: '27px'}}>Contact Support</a>
                  </div>

                </div>

                <div className="dashboard-content">

                  <div className="card custom-card-help">
                    <div className="card-body">
                      <div className="custom-card-help-left">
                        <h5>How does TruckMemo Dispatcher App work?</h5>
                        <p>
                          TruckMemo Systems’ new addition to the auto transport market - TruckMemo Dispatcher Web App helps
                          Auto Hauler companies to manage transportation loads.
                        </p>
                        <p>
                          Here is the how-to-use description of the main features.
                        </p>
                        <p>
                          Home Page: On the top, you see the number of licensed users in your database. Those are drivers
                          you have purchased the TruckMemo Dispatch plan for. The next item shows a number of active drivers,
                          and the last item shows the number of drivers who have assigned loads at the moment.
                        </p>
                        <p>
                          There are 4 widgets on the home page. First widget shows statistics on how many loads are currently active,
                          delivered or delayed. You can filter this information by selecting specific driver.
                        </p>
                        <p>
                          The second Widget shows completed loads per day and compares it to the last period. You can view weekly or yearly
                          report. The yearly report shows your gross earnings by months.
                        </p>
                        <p>
                          Third section shows top 10 clients which means the customers where your drivers deliver cars. In the columns you
                          see how many cars you have delivered to this specific client and what is gross amount of all delivered loads. With
                          this tool, you can easily do the analysis of your business. In the fourth section you see the list of drivers and
                          whether they have assigned, picked up or no loads. You can see what capacity they have left on the trailer and how
                          many more cars they can accept. This helps you quickly find suitable loads for your driver that can fit in the
                          empty space of the trailer.
                        </p>
                      </div>
                      <div className="custom-card-help-right">
                        <img src="assets/images/help-main-img.jpg" alt="app img"/>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="custom-card-help-header">
                        <h5>How to use our App</h5>
                        <a className="btn custom-btn" href="admin@truckmemo.com">Ask question</a>
                      </div>

                      <div className="help-item">
                        <div className="help-item-header">
                          <div className="help-item-title">How to purchase license?</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample1"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample1">
                          <div className="help-item-body">
                            On home page you see the button “Upgrade Now”. Press on the button. You see how many active drivers you
                            currently have. By pressing on “-” or “+” you can downgrade or upgrade subscription plan. On the right side,
                            you see total monthly payment and today’s total which reflects any changes done to the current subscription plan.
                            Pay attention that if you are making changes in the middle of the current billing period, the actual change will
                            happen after the billing month is over (for license decrease or cancelation). For
                            example, if you cancel subscription for one driver and it is 10 days before the next billing date then the driver
                            will still be active for the next 10 days.
                          </div>
                        </div>
                      </div>
                      <div className="help-item">
                        <div className="help-item-header">
                          <div className="help-item-title">How to create a Load?</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample2"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample2">
                          <div className="help-item-body">
                            <p>Load page consists of three sections: Search, Active Loads and Map.</p>
                            <ol>
                              <li>You can search for any load in the Active loads section. Just type load ID, vehicle or customer info.</li>
                              <li>In the Active load section you see the list of loads that you can edit or delete. Press the Edit icon on
                                your right side and you can change any information in the load. Pickup/Delivery dates, Add additional
                                vehicles, change vehicle info, reassign to new driver,
                                etc.
                              </li>
                            </ol>

                            <p>How to create a load. On the right side, there is a button “Create Load”. By pressing it, you open a blank
                              load page where:</p>

                            <ol>
                              <li>You can fill out information manually or copy from another website and paste in the appropriate fields or
                              </li>
                              <li>You can upload loads from Central dispatch or ACV auctions load board.</li>
                            </ol>
                            <p>
                              Download PDF file from central dispatch or ACV auctions load board and simply upload in your load page.
                              The information will be automatically pasted from the PDF to the load fields. You can review to make sure
                              info was accurately imported and assign load to the driver.
                            </p>
                            <p>
                              Map. In the third section you see drivers and their locations. The location is updated every minute and by
                              clicking on the truck icon you can see what loads this driver has assigned and from where to where he/she is
                              transporting cars. On the Loads page you can see all active loads.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="help-item">
                        <div className="help-item-header">
                          <div className="help-item-title">How to add/delete your Transporters?</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample3"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample3">
                          <div className="help-item-body">
                            On the Transporters page, you see the list of drivers you have invited or is already connected to your company.
                            In the search field, you can lookup transporters in our database. Once you find driver you can send the invite.
                            In order driver to be connected to you, he/she should open TruckMemo mobile app and accept the invite. If a driver,
                            who starts working with you, is not present in the database you can ask him/her to create transporter account in
                            TruckMemo mobile app. After the transporter successfully created an account in TruckMemo mobile app, you can search
                            for him again, and send an invite to connect to your company.
                          </div>
                        </div>
                      </div>

                      <div className="help-item">
                        <div className="help-item-header">
                          <div className="help-item-title">How to add Clients?</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample4"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample4">
                          <div className="help-item-body">
                            In the Clients section, you see clients who are in the TruckMemo database. These clients list is created based
                            on TruckMemo transporters deliveries or pickups, when they create loads they also create profiles of dealerships,
                            brokers, car yards, auctions, etc. You can start creating client by pressing on “Create New Client” button.
                            Fill out all necessary information and press save. Below the button
                            you see how many clients are in the database. You can start creating load for each client simply pressing on
                            “Create Load” which will open blank load page simultaneously pasting this client's info in the pickup field.
                          </div>
                        </div>
                      </div>
                      <div className="help-item">
                        <div className="help-item-header">
                          <div className="help-item-title">How to use the History page?</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample5"
                            aria-expanded="false"
                            className="collapsed"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample5">
                          <div className="help-item-body">
                            History page shows all your delivered loads. You can search for loads by load id,
                            Pickup or delivery date and address. You can
                            download PDF of each load instantly to your computer by pressing “Save as PDF” button.
                          </div>
                        </div>
                      </div>
                      <div className="help-item">
                        <div className="help-item-header">
                          <div className="help-item-title">How the billing period and the payments are working?</div>
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample6"
                            className="collapsed"
                            aria-expanded="false"
                            aria-controls="collapseExample"/>
                        </div>
                        <div className="collapse" id="collapseExample6">
                          <div className="help-item-body">
                            <p>Validity for Dispatcher license - 1 month. It is dynamically recalculated after each purchase/upgrade.
                            </p>
                            <p>
                              For example, you purchased the license for 10 transporters on 07/20/2019. You paid 45x10 = $450.
                              Your next payment scheduled for 8/20/2019, and should be another $450. Your billing period, in this case,
                              is from 07/20/2019 - 08/20/2019).
                            </p>

                            <p>
                              Let's imagine the situation that on 08/10/2019 you've decided to upgrade to 11 Transporters (to add 1 more).
                              Now the new billing period will be from 08/10/2019 till 09/10/2019. You need to pay for this one license
                              that you want to add plus the difference between the monthly payment for 10 Transporters that you did before
                              minus the amount that has already been paid and not used (in this case from 8/20 till 09/10).
                              So the payment would be: $45 + ($300 - $100) = $245.
                            </p>

                            <p>Based on a new billing period, the next scheduled payment will be on 09/10/2019 and it will be $345.
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Help);
