import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import PieChart from '../../MainContent/Chartstypes/Chartist/PieChart';
import './style.css'
import {Tooltip} from "reactstrap";
import {bindActionCreators} from "redux";
import {getDashboardData} from "../MainPage/dashboardAction";
import TransportersAPI from "../../../api/transportersAPI";
import DashboardAPI from "../../../api/dashboardApi";
import Select from 'react-select';

class LoadsStat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip_top: false,
      transporters: [],
      selectedOption: '',
      statistic: '',
      defaultSelectValue: {label: 'All drivers', value: 'All drivers'}
    };
  }

  async componentDidMount() {
    const data = await TransportersAPI.GetTransportersStatWithLoad()
    const statistic = await DashboardAPI.GetTransportersStat();
    const transportersStatusFilter = data.data.data.filter(item => item.status !== 'invited')

    const transporters = transportersStatusFilter.map((item) => {
      return {value: item.id, label: `${item.name}`, id: item.id}
    })

    transporters.unshift({value: '', label: 'All drivers', id: ''})

    this.setState({transporters, statistic: statistic.data.data})
  }

  changeOption = async value => {

    const data = value.id ?
      await DashboardAPI.GetTransportersStat({transporter_id: value.id}) :
      await DashboardAPI.GetTransportersStat();

    this.setState({
      selectedOption: value,
      statistic: data.data.data
    });
  };

  render() {

    const {defaultSelectValue, transporters, statistic} = this.state

    return (
      <AUX>
        <div className="card">
          <div className="card-body custom-card-body">

            <h5 className="mt-0">Loads Stat 
              {this.props.refreshing ? 
                <span style={{ marginLeft: '3px', marginRight: '3px' }}><i className="fa fa-spinner fa-spin"/></span>
               : null}
              <span className="custom-info" id="TooltipTop1">i</span>
              <Tooltip
                placement="top"
                isOpen={this.state.tooltip_top}
                target="TooltipTop1" toggle={() => this.setState({tooltip_top: !this.state.tooltip_top})}>
                This widget shows statistics on how many of your loads are currently active, delivered or being delayed.
                You can filter this information for a specific Transporter by selecting him/her on the top of the widget.
              </Tooltip>
            </h5>

            <div className="custom-form-control-wrap">
              <Select
                className="custom-form-control-select"
                defaultValue={defaultSelectValue}
                onChange={this.changeOption}
                options={transporters}
              />
            </div>

            <div className="custom-pie-chart">
              <PieChart statistic={statistic}/>
            </div>

            <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
              <li className="list-inline-item">
                <h5 className="mb-0">{statistic.active || 0}</h5>
                <p className="text-muted">Active</p>
              </li>
              <li className="list-inline-item">
                <h5 className="mb-0">{statistic.completed || 0}</h5>
                <p className="text-muted">Delivered</p>
              </li>
              <li className="list-inline-item">
                <h5 className="mb-0">{statistic.expired || 0}</h5>
                <p className="text-muted">Delayed</p>
              </li>
            </ul>

          </div>
        </div>
      </AUX>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.dashboardReducer,
  };
}


const mapDispatchToProps = dipatch => ({
  ...bindActionCreators(
    {
      getDashboardData,
    },
    dipatch
  ),
});

export default connect(mapStatetoProps, mapDispatchToProps)(LoadsStat);
