import TransportersAPI from "../api/transportersAPI";

const getDrivers = async () => {
  const data = await TransportersAPI.GetTransportersStatWithLoad()
  const transportersStatusFilter = data.data.data.filter(item => item.status !== 'invited')
  const transporters = transportersStatusFilter.map((item) => {
    return {value: item.id, label: `${item.name}`, id: item.id}
  })
  transporters.unshift({label: 'All drivers', value: ''})
  return transporters
}

export default getDrivers
