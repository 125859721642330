import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { openTutorialVideo, openAddTransportersVideo } from "../../../helpers/LinkUtils";
import "./style.css";

class SpeechBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatcherProStatus: undefined,
      awaitForRender: false,
      popupOpen: true,
      subscriptionStatus: "",
    };
  }

  async componentDidMount() {}

  render() {
    const { popupOpen } = this.state;
    const { speechMode } = this.props;

    return (
      <AUX>
        {this.props.enabled ? (
          <div
          className={
            popupOpen
              ? (speechMode === "addTransporter" ? "speech-bubble speech-bubble-expand" : (speechMode === "upgradeSubscription" ? "speech-bubble-subscription speech-bubble-expand" : "speech-bubble-transporter speech-bubble-expand"))
              : (speechMode === "addTransporter" ? "speech-bubble speech-bubble-shrink" : (speechMode === "upgradeSubscription" ? "speech-bubble-subscription speech-bubble-shrink" : "speech-bubble-transporter speech-bubble-shrink"))
          }
        >
          <button
            className="button-speech-bubble"
            name="button-speech-bubble"
            title="Close"
            aria-label="button-speech-bubble"
            onClick={this.onClose}
          >
            <i className="mdi dripicons-cross" />
          </button>
          {speechMode === "addTransporter" && (
            <div className="speech-content">
              <h2 className="speech-title">
                Using TruckMemo for the first time?
              </h2>
              <h5 className="speech-text">
                Go to
                <Link to="/transporters" className="active speech-link">
                  <span> Transporters </span>
                </Link>
                page to connect with drivers and start dispatching.
              </h5>
              <h5 className="speech-text">
                Or watch tutorial video on{" "}
                <button
                  className="youtube-speech-bubble"
                  name="button-speech-bubble"
                  title="How to start with TruckMemo"
                  aria-label="button-speech-bubble"
                  onClick={() => openTutorialVideo()}
                >
                  <i className="fa fa-youtube-square" />
                </button>{" "}
                to see more.
              </h5>
            </div>
          )}
          {speechMode === "findTransporter" && (
            <div className="speech-content">
              <h5 className="speech-text">
                Find already registered drivers to connect or tell your driver to create TruckMemo mobile application profile.
              </h5>
              <h5 className="speech-text">
                How to add and activate drivers watch following{" "}
                <button
                  className="youtube-speech-bubble"
                  name="button-speech-bubble"
                  title="How to add and activate drivers"
                  aria-label="button-speech-bubble"
                  onClick={() => openAddTransportersVideo()}
                >
                  <i className="fa fa-youtube-square" />
                </button>{" "}
                video.
              </h5>
            </div>
          )}
          {speechMode === "upgradeSubscription" && (
            <div className="speech-content">
              <h5 className="speech-text">
                To activate new driver you need to purchase additional license, visit <Link to="/subscription" className="active speech-link">
                  <span> Subscription </span>
                </Link> 
                page to activate you driver.
              </h5>
            </div>
          )}
        </div>
        ) : null}
      </AUX>
    );
  }

  onClose = () => {
    const { closeCallBack } = this.props;

    this.setState(
      {
        popupOpen: false,
      },
      () => {
        if (closeCallBack) {
          closeCallBack();
        }
      }
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeechBubble);
