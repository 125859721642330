import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: "100vw",
    backgroundImage: "url('assets/images/background.png')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
    padding: "10px",
    boxSizing: "border-box",
  },
  box: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
    maxWidth: "90%",
    width: "600px",
    maxHeight: "90vh",
    overflowY: "auto", // Scroll only inside the box if needed
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 'bold',
    margin: 0,
    fontSize: "22px",
    lineHeight: "22px",
    color: "#333",
    textTransform: "uppercase",
  },
  paragraph: {
    marginTop: "15px",
    fontSize: "1rem",
    color: "#555",
    textAlign: "justify",
    overflowWrap: "break-word",
  },
  appLinks: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "20px",
    flexWrap: "wrap",
  },
  appLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#555",
  },
  appIcon: {
    maxWidth: "150px",
    height: "auto",
    margin: "10px",
  },
  appLogo: {
    margin: "10px",
    maxWidth: '250px',
    height: 'auto',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

// Responsive styles for mobile devices
const mobileStyles = {
  box: {
    maxWidth: "95%",
    width: "100%",
    height: "100vh",
    maxHeight: "none",
    overflowY: "auto", // Allow scrolling inside the box
    padding: "15px",
    borderRadius: "0px",
  },
};

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 600,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateIsMobile);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth < 600 });
  };

  render() {
    const { isMobile } = this.state;

    return (
      <AUX>
        <div style={styles.container}>
          <div style={isMobile ? { ...styles.box, ...mobileStyles.box } : styles.box}>
            <span style={styles.appLinks}>
            <img
              style={styles.appLogo}
              src="assets/images/logo-full.png"
              alt="logo"
            />
            </span>
            <hr style={{width: '100%', height: '2px' }} />
            <h1 style={styles.title}>Truckmemo Dispatcher Portal</h1>
            <p style={styles.paragraph}>
              Welcome to our Dispatcher Portal. This application is a robust and intuitive platform designed to streamline logistics and simplify operational workflows for dispatchers. With tools to manage shipments, monitor fleet performance, and optimize communication, it enables efficient coordination between drivers and stakeholders. Real-time updates and analytics provide valuable insights, ensuring deliveries are executed on time and with precision. Designed for ease of use, it offers a seamless experience while maintaining a high standard of data security and reliability.
            </p>
            <p style={styles.paragraph}>
              Additionally, our Truckmemo mobile application for drivers complements the Dispatcher Portal, providing tools for drivers to manage their tasks, receive updates, and stay connected while on the road. 
            </p>
            <div style={styles.appLinks}>
              <a
                href="https://play.google.com/store/apps/details?id=com.truckmemo"
                target="_blank"
                rel="noopener noreferrer"
                style={styles.appLink}
              >
                <img
                  src="assets/images/google-play-icon.png"
                  alt="Google Play Store"
                  style={styles.appIcon}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/truckmemo/id6740175515"
                target="_blank"
                rel="noopener noreferrer"
                style={styles.appLink}
              >
                <img
                  src="assets/images/app-store-icon.png"
                  alt="Apple App Store"
                  style={styles.appIcon}
                />
              </a>
            </div>
            <hr style={{width: '100%', height: '2px' }} />
            &copy; 2024 Transcontinental Group Holdings Inc. All rights reserved.
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(About);